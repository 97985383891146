<template>
	<v-layout row wrap align-center justify-center>
		<v-flex shrink justify-end align-center>
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-btn outline round flat :icon="isMinimized" small color="green" v-on="on" @click="setTodayFilter">
						<v-icon small>today</v-icon>
						<span v-if="!isMinimized" v-t="'tasks.filters.today'" class="ml-2" />
					</v-btn>
				</template>
				<span v-t="'tasks.filters.today_tooltip'" />
			</v-tooltip>
			<v-tooltip bottom>
				<template v-slot:activator="{ on }">
					<v-btn outline round flat :icon="isMinimized" small color="purple" v-on="on" @click="setWeekFilter">
						<v-icon small>date_range</v-icon>
						<span v-if="!isMinimized" v-t="'tasks.filters.next_week'" class="ml-2" />
					</v-btn>
				</template>
				<span v-t="'tasks.filters.next_week_tooltip'" />
			</v-tooltip>
		</v-flex>
		<v-flex v-if="!minimized" shrink align-center justify-center>
			<DatesFilter v-model="filters.dueDateInterval" @after-date="updateAfterDate" @before-date="updateBeforeDate" />
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'TasksManagerFilterDueDate',
	components: {
		DatesFilter: () => ({
			component: import('@/components/Commons/DatesFilter')
		})
	},
	props: {
		minimized: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	computed: {
		...mapState({
			filters: state => state.tasks.filters
		}),
		isMinimized: function () {
			return this.minimized || this.$vuetify.breakpoint.lgAndDown
		}
	},
	methods: {
		setTodayFilter: function () {
			const today = new Date()
			this.filters.dueDateInterval.afterDate = (today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()).replace(/-/g, '/')
			this.filters.dueDateInterval.beforeDate = (today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()).replace(/-/g, '/')
		},
		setWeekFilter: function () {
			const weekstart = new Date()
			const weekend = new Date()
			weekend.setDate(weekstart.getDate() + 7)
			this.filters.dueDateInterval.afterDate = (weekstart.getFullYear() + '-' + (weekstart.getMonth() + 1) + '-' + weekstart.getDate()).replace(/-/g, '/')
			this.filters.dueDateInterval.beforeDate = (weekend.getFullYear() + '-' + (weekend.getMonth() + 1) + '-' + weekend.getDate()).replace(/-/g, '/')
		},
		updateAfterDate: function (newValue) {
			this.filters.dueDateInterval.afterDate = newValue
		},
		updateBeforeDate: function (newValue) {
			this.filters.dueDateInterval.beforeDate = newValue
		}
	}
}
</script>
